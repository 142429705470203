import router from '@/router/index.js';
import {
    ElMessage
} from 'element-plus';
const state = {
    photoList:[]
}
const getters = {

}
const  mutations= {
    setPhotoList(state, photoList) {
        state.photoList = photoList;
    },
}
const actions = {
    setPhotoList({
        state,
        commit
    }, value) {
        commit('setPhotoList', value)
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}