import request from "@/utils/request";
const activeOrder = (data)=>{
    return request({
        url: "/v1/web/booster/active/order",
        method: "post",
        data
    });
}
const historyOrder = (data)=>{
    return request({
        url: "/v1/web/booster/history/order",
        method: "post",
        data
    });
}
const completeOrder = (data)=>{
    return request({
        url: "/v1/web/booster/order/complete",
        method: "post",
        data
    });
}
const queue = (data)=>{
    return request({
        url: "/v1/web/booster/queue",
        method: "post",
        data
    });
}
const boosterList = (data)=>{
    return request({
        url: "/v1/web/booster/list",
        method: "post",
        data
    });
}
const orderInfo = (data)=>{
    return request({
        url: "/v1/web/booster/order/info",
        method: "post",
        data
    });
}
const profileInfo = (data)=>{
    return request({
        url: "/v1/web/booster/profile/info",
        method: "post",
        data
    });
}
const orderComplete = (data)=>{
    return request({
        url: "/v1/web/booster/order/complete",
        method: "post",
        data
    });
}
const orderStart = (data)=>{
    return request({
        url: "/v1/web/booster/order/start",
        method: "post",
        data
    });
}
const boosterTake = (data)=>{
    return request({
        url: "/v1/web/booster/take",
        method: "post",
        data
    });
}
const boosterPass = (data)=>{
    return request({
        url: "/v1/web/booster/pass",
        method: "post",
        data
    });
}
const boosterProfileInfo = (data)=>{
    return request({
        url: "/v1/web/booster/profile/info",
        method: "post",
        data
    });
}
const calculatorDivision = (data)=>{
    return request({
        url: "/v1/web/calculator/division",
        method: "post",
        data
    });
}
const boosterProfileInfoEdit = (data)=>{
    return request({
        url: "/v1/web/booster/profile/info/edit",
        method: "post",
        data
    });
}
const boosterProfilePortfolio = (data)=>{
    return request({
        url: "/v1/web/booster/profile/portfolio",
        method: "post",
        data
    });
}
const boosterProfilePortfolioEdit = (data)=>{
    return request({
        url: "/v1/web/booster/profile/portfolio/edit",
        method: "post",
        data
    });
}
const boosterProfileReviews = (data)=>{
    return request({
        url: "/v1/web/booster/profile/reviews",
        method: "post",
        data
    });
}
const calculatorAgent = (data)=>{
    return request({
        url: "/v1/web/calculator/agent",
        method: "post",
        data
    });
}
const chatSupport = (data)=>{
    return request({
        url: "/v1/web/chat/support",
        method: "post",
        data
    });
}
const chatRecord = (data)=>{
    return request({
        url: "/v1/web/chat/record",
        method: "post",
        data
    });
}
const sendPrivateMessage = (data)=>{
    return request({
        url: "/v1/web/chat/send/private/message",
        method: "post",
        data
    });
}

const boosterAccount = (data)=>{
    return request({
        url: "/v1/web/booster/account",
        method: "post",
        data
    });
}
const boosterAccountStatus = (data)=>{
    return request({
        url: "/v1/web/booster/account/status",
        method: "post",
        data
    });
}
const boosterWithdraw = (data)=>{
    return request({
        url: "/v1/web/booster/withdraw",
        method: "post",
        data
    });
}
const boosterBalance = (data)=>{
    return request({
        url: "/v1/web/booster/balance",
        method: "post",
        data
    });
}
const supportCreate = (data)=>{
    return request({
        url: "/v1/web/support/create",
        method: "post",
        data
    });
}
const supportList = (data)=>{
    return request({
        url: "/v1/web/support/list",
        method: "post",
        data
    });
}
const supportStatus = (data)=>{
    return request({
        url: "/v1/web/support/status",
        method: "post",
        data
    });
}
const transactionHistory = (data)=>{
    return request({
        url: "/v1/web/booster/transaction/history",
        method: "post",
        data
    });
}



const boostFineList = (data) => {
	return request({
		url: "/v1/web/boost/fine",
		method: "POST",
		data
	})
}

const boostRuleList = (data) => {
	return request({
		url: "/v1/web/boost/rule",
		method: "POST",
		data
	})
}
const blogList = (data) => {
	return request({
		url: "/v1/web/index/blog",
		method: "POST",
		data
	})
}
const uploadImages = (data) => {
	return request({
		url: "/v1/web/upload/images",
		method: "POST",
		data
	})
}
const indexAnnouncement = (data) => {
	return request({
		url: "/v1/web/index/announcement",
		method: "POST",
		data
	})
}
const transactionReport = (data) => {
	return request({
		url: "/v1/web/booster/transaction/report",
		method: "POST",
		data
	})
}
const orderReport = (data) => {
	return request({
		url: "/v1/web/booster/order/report",
		method: "POST",
		data
	})
}
const indexMenu = (data) => {
	return request({
		url: "/v1/web/index/menu",
		method: "POST",
		data
	})
}
const queueSetting = (data) => {
	return request({
		url: "/v1/web/booster/queue/setting",
		method: "POST",
		data
	})
}
const authMe = (data) => {
	return request({
		url: "/v1/web/auth/me",
		method: "POST",
		data
	})
}
const orderStatus = (data) => {
	return request({
		url: "/v1/web/booster/order/status",
		method: "POST",
		data
	})
}
const reviewCreate = (data) => {
	return request({
		url: "/v1/web/booster/review/create",
		method: "POST",
		data
	})
}
const reviewDelete = (data) => {
	return request({
		url: "/v1/web/booster/review/delete",
		method: "POST",
		data
	})
}


export default {
    reviewDelete,
    reviewCreate,
    orderStatus,
    authMe,
    queueSetting,
    indexMenu,
    orderReport,
    transactionReport,
    indexAnnouncement,
    uploadImages,
    blogList,
	boostRuleList,
	boostFineList,
    transactionHistory,
    sendPrivateMessage,
    chatRecord,
    supportStatus,
    supportList,
    supportCreate,
    boosterBalance,
    boosterWithdraw,
    boosterAccountStatus,
    boosterAccount,
    chatSupport,
    calculatorAgent,
    boosterProfileReviews,
    boosterProfilePortfolioEdit,
    boosterProfilePortfolio,
    boosterProfileInfoEdit,
    calculatorDivision,
    boosterProfileInfo,
    boosterPass,
    boosterTake,
    orderComplete,
    profileInfo,
    orderInfo,
    activeOrder,
    historyOrder,
    completeOrder,
    queue,
    boosterList,
    orderStart
}