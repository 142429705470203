export default{
    common:{
    },
    home:{
        jobs:"JOBS",
        login:"LOG IN",
        elevateyourgamingexperience:"Elevate Your Gaming Experience with Goboost",
        calculateyourboost:"CALCULATE YOUR BOOST"
    },
    login:{
        anew:"A NEW",
        gamingera:" GAMING ERA",
        startstoday:"STARTS TODAY",
        signin:"Sign in",
        registration:"Registration",
        user:"User",
        booster:"Booster",
        username:"User name",
        email:"Email",
        send:"send",
        code:"Code",
        password:"Password",
        register:"REGISTER",
        signinup:"SIGN IN",
        forgotpassword:"Forgot password?",
        remembermefor:"Remember me for 30 days",
        subscribeme:"Subscribe me to your newsletter (we promise no spam, only good deals)",
        enteryourusername:"Enter your username",
        enteryouremail:"Enter your Email",
        enteryourcode:"Enter your code",
        enteryourpassword:"Enter your password"
    }
};