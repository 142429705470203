import router from '@/router/index.js';
import {
    removeAllCookie,
    getCookie,
    setCookie
} from "@/utils/cookie";
import {
    ElMessage
} from 'element-plus';
import { 
    getPrivacy,
    getTerms,
    getStatistics
} from "@/api/user_home.js"
const state = {
    privacyList:null,
    privacyTitle:null,
    termsList:null,
    termsTitle:null,
    average_win_rate:null,
    boosters_online:null,
    order_completed:null
}
const getters = {

}
const mutations = {
    setPrivacyList(state, privacyList) {
        state.privacyList = privacyList
    },
    setPrivacyTitle(state, privacyTitle) {
        state.privacyTitle = privacyTitle
    },
    setTermsList(state, termsList) {
        state.termsList = termsList
    },
    setTermsTitle(state, termsTitle) {
        state.termsTitle = termsTitle
    },
    setAverageWinRate(state, average_win_rate) {
        state.average_win_rate = average_win_rate
    },
    setBoostersOnline(state, boosters_online) {
        state.boosters_online = boosters_online
    },
    setOrderCompleted(state, order_completed) {
        state.order_completed = order_completed
    },
}
const actions = {
    setPrivacyList({ state, commit }, value) {
        commit('setPrivacyList', value)
    },
    setPrivacyTitle({ state, commit }, value) {
        commit('setPrivacyTitle', value)
    },
    setTermsList({ state, commit }, value) {
        commit('setTermsList', value)
    },
    setTermsTitle({ state, commit }, value) {
        commit('setTermsTitle', value)
    },
    setAverageWinRate({ state, commit }, value) {
        commit('setAverageWinRate', value)
    },
    setBoostersOnline({ state, commit }, value) {
        commit('setBoostersOnline', value)
    },
    setOrderCompleted({ state, commit }, value) {
        commit('setOrderCompleted', value)
    },
    async getPrivacy({ commit,dispatch },value){
        try {
            let obj = value
            const res = await getPrivacy(obj)
            if(res.code == 200){
                let title_arr = []
                let title_obj = {}
                let content_arr = []
                let content_obj = {}
                res.data.forEach(element => {
                    title_obj = {
                        id:element.id,
                        title:element.title,
                    }
                    content_obj = {
                        id:element.id,
                        title:element.title,
                        description:element.description,
                    }
                    title_arr.push(title_obj)
                    content_arr.push(content_obj)
                });
                commit('setPrivacyTitle',title_arr)
                commit('setPrivacyList',content_arr)
            }
        }catch (e) {
        }
    },
    async getTerms({ commit,dispatch },value){
        try {
            let obj = value
            const res = await getTerms(obj)
            if(res.code == 200){
                let title_arr = []
                let title_obj = {}
                let content_arr = []
                let content_obj = {}
                res.data.forEach(element => {
                    title_obj = {
                        id:element.id,
                        title:element.title,
                    }
                    content_obj = {
                        id:element.id,
                        title:element.title,
                        description:element.description,
                    }
                    title_arr.push(title_obj)
                    content_arr.push(content_obj)
                });
                commit('setTermsList',content_arr)
                commit('setTermsTitle',title_arr)
            }
        }catch (e) {
        }
    },
    async getStatistics({ commit,dispatch },value){
        try {
            let obj = value
            const res = await getStatistics(obj)
            if(res.code == 200){
                commit('setAverageWinRate',res.data.average_win_rate)
                commit('setBoostersOnline',res.data.boosters_online)
                commit('setOrderCompleted',res.data.order_completed)
            }
        }catch (e) {
        }
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}