import Cookie from 'js-cookie'

export function setCookie(key, value) {
    Cookie.set(key, value)
}
export function getCookie(key) {
    return Cookie.get(key)
}
export function removeCookie(key) {
    Cookie.remove(key)
}
export function removeAllCookie() {
    console.log('removeAllCookie====')
    Object.keys(Cookie.get()).forEach(cookieName =>{
        console.log(cookieName)
        Cookie.remove(cookieName);
    });
}
export function getTime(key,value){
    var date = new Date(key * 1000);
    var Y = date.getFullYear();
    var M =
            (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) ;
    var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate())
    var h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours())
    var m = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes())
    var s = (date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds())
    if(value == 'pay'){
        return D + '.' + M + '.' + Y
    }else if(value == 'pay_mh'){
        return h + ':' + m
    }
}