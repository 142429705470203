import router from '@/router/index.js';
import {
    removeAllCookie,
    getCookie,
    setCookie
} from "@/utils/cookie";
import {
    ElMessage
} from 'element-plus';
import { supportCreate } from "@/api/support.js"
const state = {
    name:'',
    email:'',
    question:'',
    ifcreate:''
}
const getters = {

}
const mutations = {
    setName(state, name) {
        state.name = name
    },
    setEmail(state, email) {
        state.email = email
    },
    setQuestion(state, question) {
        state.question = question
    },
    setIfcreate(state, ifcreate) {
        state.ifcreate = ifcreate
    },

}
const actions = {
    setName({ state, commit }, value) {
        commit('setName', value)
    },
    setEmail({ state, commit }, value) {
        commit('setEmail', value)
    },
    setQuestion({ state, commit }, value) {
        commit('setQuestion', value)
    },
    setIfcreate({ state, commit }, value) {
        commit('setIfcreate', value)
    },
    async onCreate({ commit,dispatch },value){
        try {
            let obj = value
            const res = await supportCreate(obj)
            if(res.code == 200){
               commit('setIfcreate','yes')
               setCookie('support_id',res.data.support_id)
               setCookie('admin_id',res.data.admin_id)
               setCookie('admin_im_id',res.data.admin_im_id)
            }
        }catch (e) {
            console.log('errot========')
            console.error(e)
        }
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}