import request from "@/utils/request"
export function getCalculateList(data){
    return request({
        url: "/v1/web/calculator/list",
        method: "post",
        data
    });
}
export function getCalculateAcc(data){
    return request({
        url: "/v1/web/calculator/acc",
        method: "post",
        data
    });
}
export function getCalculateRank(data){
    return request({
        url: "/v1/web/calculator/rank",
        method: "post",
        data
    });
}
export function getCalculateTier(data){
    return request({
        url: "/v1/web/calculator/tier",
        method: "post",
        data
    });
}
export function getCalculateAgent(data){
    return request({
        url: "/v1/web/calculator/agent",
        method: "post",
        data
    });
}
export function getOptionalfeature(data){
    return request({
        url: "/v1/web/calculator/optional/feature",
        method: "post",
        data
    });
}
export function getRankboost(data){
    return request({
        url: "/v1/web/calculator/rank/boosting",
        method: "post",
        data
    });
}
export function buyRankboost(data){
    return request({
        url: "/v1/web/calculator/buy/rank/boosting",
        method: "post",
        data
    });
}
export function buyPlacementMatches(data){
    return request({
        url: "/v1/web/calculator/buy/placement/matches",
        method: "post",
        data
    });
}
export function getPlacementMatches(data){
    return request({
        url: "/v1/web/calculator/placement/matches",
        method: "post",
        data
    });
}
export function chooseBoosterList(data){
    return request({
        url: "/v1/web/calculator/choose/booster",
        method: "post",
        data
    });
}
export function promoCode(data){
    return request({
        url: "/v1/web/calculator/promo/code",
        method: "post",
        data
    });
}
export function getDivision(data){
    return request({
        url: "/v1/web/calculator/division",
        method: "post",
        data
    });
}
export function buyUnratedGames(data){
    return request({
        url: "/v1/web/calculator/buy/unrated/games",
        method: "post",
        data
    });
}
export function getUnratedGames(data){
    return request({
        url: "/v1/web/calculator/unrated/games",
        method: "post",
        data
    });
}
export function onOrderLogin(data){
    return request({
        url: "/v1/web/order/login",
        method: "post",
        data
    });
}
