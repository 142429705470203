import { createI18n } from "vue-i18n";
import en from "./en";

const i18n = createI18n({
    locale: "en", // default lan
    legacy: false, //no use old Vue I18n mode
    globalInjection: true,
    messages: {
        en,
    },
});

export default i18n;