let baseUrl = ''; 
let baseImgPath;
let wsAddr = '';
let apiAddr=''
if (process.env.NODE_ENV == 'development') {
    baseImgPath = '/imgs/';
    // baseUrl = 'https://goboost-st.digitalsnail.cn/api'
    // apiAddr = 'https://im.digitalsnail.cn/';
    // wsAddr = "wss://wconnect.digitalsnail.cn/im/"

    baseUrl = "https://api.goboost.cc/api"
    apiAddr = "https://im.goboost.cc/api"
    wsAddr = "wss://im.goboost.cc/msg_gateway"

}else if(process.env.NODE_ENV == 'production'){
    baseImgPath = '';
    // baseUrl = "https://goboost-st.digitalsnail.cn/api";
    // apiAddr = 'https://im.digitalsnail.cn/';
    // wsAddr = "wss://wconnect.digitalsnail.cn/im/"

    baseUrl = "https://api.goboost.cc/api"
    apiAddr = "https://im.goboost.cc/api"
    wsAddr = "wss://im.goboost.cc/msg_gateway"
}
export {
    baseUrl,
    baseImgPath,
    wsAddr,
    apiAddr
}