import axios from 'axios'
import { baseUrl } from '../config/env'
import Cookies from 'js-cookie'
import { ElMessage } from 'element-plus'
import router from '@/router/index.js';
import { removeAllCookie,getCookie } from "@/utils/cookie";
// create an axios instance
const service = axios.create({
  //baseURL: "", 
  baseURL: baseUrl,  
  //baseURL: process.env.VUE_APP_BASE_API,
  timeout: 10000
})
service.interceptors.request.use(
  config => {
    //'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    //config.headers['X-CSRF-TOKEN'] = Cookies.get('token')
    // config.headers['Content-Type'] = "form/data"
    config.headers['X-Token'] = Cookies.get('token');
    config.headers['Authorization'] = 'Bearer '+Cookies.get('token')
    // config.headers['Accept-Language'] = ''+Cookies.get('lan')
    return config
  },
  error => {
    // do something with request error
    console.log('request=========')
    console.log(error) // for debug
    return Promise.reject(error)
  }
)
service.interceptors.response.use(
  response => {
    const res = response.data;
    if (res.code == 200 || res.code == 200021|| res.code == 200006 || res.code == 405009 || res.code == 504001 || res.code == 200204 || res.code == 200300 || res.code == 200301 || res.code == 200400 || res.code == 407001 || res.code == 407002) {
      return res
    }
    else {
      console.log('error====')
      ElMessage({
        message: res.message,
        type: 'error',
        duration: 5 * 1000
      })
      return 
    }
  },
  error => {
    if(error.response.status == 403||error.response.status == 401){
        Cookies.remove('token');
        Cookies.remove('expires_in');
        Cookies.remove('token_type');
     
      ElMessage({
        message: "Please sign in.",
        type: 'error',
        duration: 5 * 1000
      })
      router.push('/login');
    }
    return status <= 300;
  }
)
export default service