import router from '@/router/index.js';
import {
    removeAllCookie,
    getCookie,
    setCookie
} from "@/utils/cookie";
import {
    ElMessage
} from 'element-plus';
import { 
    getProfileInfo,
    getBoosterPortfolio,
    getBoosterReviews,
    getBoosterOrders
} from "@/api/user_booster.js"
const state = {
    username:'',
    avatar:'',
    country:'',
    rate:'',
    current_rank_image:'',
    current_rank:'',
    details:'',
    win_rate:null,
    recommended:null,
    five_star_reviews:null,
    reviews:null,
    portfolioList:[],
    reviewsList:[],
    portfolioList_pre:[],
    portfolioList_next:[],
    orderList:[],

    orderPage:1,
    orderLimit:7,
    orderTotal:null,
    reviewsPage:1,
    reviewsLimit:2,
    reviewsTotal:null,

    orderTotalPages:null,
    reviewsTotalPages:null,

    showMore:'no',
    portfolioList_pre2:[],
    portfolioList_next2:[],

    //add
    orders:null
    

}
const getters = {

}
const mutations = {
    setShowMore(state, showMore) {
        state.showMore = showMore
    },
    setPortfolioListpre2(state, portfolioList_pre2){
        state.portfolioList_pre2 = portfolioList_pre2
    },
    setPortfolioListnext2(state, portfolioList_next2){
        state.portfolioList_next2 = portfolioList_next2
    },
    setUserName(state, username) {
        state.username = username
    },
    setAvatar(state, avatar) {
        state.avatar = avatar
    },
    setCountry(state, country) {
        state.country = country
    },
    setRate(state, rate) {
        state.rate = rate
    },
    setCurrentRankImage(state, current_rank_image) {
        state.current_rank_image = current_rank_image
    },
    setCurrentRank(state, current_rank) {
        state.current_rank = current_rank
    },
    setDetails(state, details) {
        state.details = details
    },
    setWinRate(state, win_rate){
        state.win_rate = win_rate
    },
    setRecommended(state, recommended){
        state.recommended = recommended
    },
    setFiveStarReviews(state, five_star_reviews){
        state.five_star_reviews = five_star_reviews
    },
    setReviews(state, reviews){
        state.reviews = reviews
    },
    setPortfolioList(state, portfolioList){
        state.portfolioList = portfolioList
    },
    setPortfolioListpre(state, portfolioList_pre){
        state.portfolioList_pre = portfolioList_pre
    },
    setPortfolioListnext(state, portfolioList_next){
        state.portfolioList_next = portfolioList_next
    },
    setReviewsList(state, reviewsList){
        state.reviewsList = reviewsList
    },
    setOrderList(state, orderList){
        state.orderList = orderList
    },
    setOrderTotal(state, orderTotal){
        state.orderTotal = orderTotal
    },
    setOrderPage(state, orderPage){
        state.orderPage = orderPage
    },
    setOrderTotalPages(state, orderTotalPages){
        state.orderTotalPages = orderTotalPages
    },

    setReviewsTotal(state, reviewsTotal){
        state.reviewsTotal = reviewsTotal
    },
    setReviewsPage(state, reviewsPage){
        state.reviewsPage = reviewsPage
    },
    setReviewsTotalPages(state, reviewsTotalPages){
        state.reviewsTotalPages = reviewsTotalPages
    },

    setOrders(state, orders){
        state.orders = orders
    },

}
const actions = {
    setShowMore({ state, commit }, value) {
        commit('setShowMore', value)
    },
    setPortfolioListpre2({ state, commit }, value) {
        commit('setPortfolioListpre2', value)
    },
    setPortfolioListnext2({ state, commit }, value) {
        commit('setPortfolioListnext2', value)
    },
    setUserName({ state, commit }, value) {
        commit('setUserName', value)
    },
    setAvatar({ state, commit }, value) {
        commit('setAvatar', value)
    },
    setCountry({ state, commit }, value) {
        commit('setCountry', value)
    },
    setRate({ state, commit }, value) {
        commit('setRate', value)
    },
    setCurrentRankImage({ state, commit }, value) {
        commit('setCurrentRankImage', value)
    },
    setCurrentRank({ state, commit }, value) {
        commit('setCurrentRank', value)
    },
    setDetails({ state, commit }, value) {
        commit('setDetails', value)
    },
    setWinRate({ state, commit }, value) {
        commit('setDetails', value)
    },
    setRecommended({ state, commit }, value) {
        commit('setRecommended', value)
    },
    setFiveStarReviews({ state, commit }, value) {
        commit('setFiveStarReviews', value)
    },
    setReviews({ state, commit }, value) {
        commit('setReviews', value)
    },
    setPortfolioList({ state, commit }, value) {
        commit('setPortfolioList', value)
    },
    setReviewsList({ state, commit }, value) {
        commit('setReviewsList', value)
    },
    setPortfolioListpre({ state, commit }, value) {
        commit('setPortfolioListpre', value)
    },
    setPortfolioListnext({ state, commit }, value) {
        commit('setPortfolioListnext', value)
    },
    setOrderList({ state, commit }, value) {
        commit('setOrderList', value)
    },
    setOrderPage({ state, commit }, value) {
        commit('setOrderPage', value)
    },
    setOrderTotalPages({ state, commit }, value) {
        commit('setOrderTotalPages', value)
    },

    setReviewsPage({ state, commit }, value) {
        commit('setReviewsPage', value)
    },
    setReviewsTotalPages({ state, commit }, value) {
        commit('setReviewsTotalPages', value)
    },
    setOrders({ state, commit }, value) {
        commit('setOrders', value)
    },





    async getProfileInfo({ commit,dispatch },value){
        try {
            let obj = value
            const res = await getProfileInfo(obj)
            if(res.code == 200){
                commit('setUserName',res.data.username)
                commit('setAvatar',res.data.avatar)
                commit('setCountry',res.data.country)
                commit('setRate',res.data.rate)
                commit('setCurrentRankImage',res.data.current_rank_image)
                commit('setCurrentRank',res.data.current_rank)
                commit('setDetails',res.data.details)
                commit('setWinRate',res.data.win_rate)
                commit('setRecommended',res.data.recommended)
                commit('setFiveStarReviews',res.data.five_star_reviews)
                commit('setReviews',res.data.reviews)
                commit('setOrders',res.data.orders)
                
            }
        }catch (e) {
        }
    },
    getBoosterPortfoliomore({ state, commit }, value){
        console.log('length===' + state.portfolioList.length)
        if(state.portfolioList.length == 7){
            let arr = state.portfolioList.slice(6,7)
            commit('setPortfolioListpre2',arr)
            commit('setPortfolioListnext2',[])
        }else if(state.portfolioList.length == 8){
            let arr = state.portfolioList.slice(6,8)
            commit('setPortfolioListpre2',arr)
            commit('setPortfolioListnext2',[])
        }else if(state.portfolioList.length == 9){
            let arr = state.portfolioList.slice(6,9)
            commit('setPortfolioListpre2',arr)
            commit('setPortfolioListnext2',[])
        }else if(state.portfolioList.length == 10){
            let arr = state.portfolioList.slice(6,9)
            let arr2 = state.portfolioList.slice(9,10)
            commit('setPortfolioListpre2',arr)
            commit('setPortfolioListnext2',arr2)
        }else if(state.portfolioList.length == 11){
            let arr = state.portfolioList.slice(6,9)
            let arr2 = state.portfolioList.slice(9,11)
            commit('setPortfolioListpre2',arr)
            commit('setPortfolioListnext2',arr2)
        }else if(state.portfolioList.length == 12){
            let arr = state.portfolioList.slice(6,9)
            let arr2 = state.portfolioList.slice(9,12)
            commit('setPortfolioListpre2',arr)
            commit('setPortfolioListnext2',arr2)
        }
        commit('setShowMore','yes')

    },
    async getBoosterPortfolio({ commit,dispatch },value){
        try {
            let obj = value
            const res = await getBoosterPortfolio(obj)
            if(res.code == 200){
                if(JSON.stringify(res.data) === '{}'){
                    commit('setPortfolioList',[])
                    commit('setPortfolioListpre',[])
                    commit('setPortfolioListnext',[])
                }else{
                    commit('setPortfolioList',res.data)
                    if(res.data.length > 6){
                        let prearr = res.data.slice(0,3)
                        let nextarr = res.data.slice(3,6)
                        commit('setPortfolioListpre',prearr)
                        commit('setPortfolioListnext',nextarr)
                        commit('setShowMore','no')
                    }else{
                        if(res.data.length == 1){
                            commit('setPortfolioListpre',res.data)
                            commit('setPortfolioListnext',[])
                        }else if(res.data.length == 2){
                            commit('setPortfolioListpre',res.data)
                            commit('setPortfolioListnext',[])
                        }else if(res.data.length == 3){
                            commit('setPortfolioListpre',res.data)
                            commit('setPortfolioListnext',[])
                        }else if(res.data.length == 4){
                            let prearr = res.data.slice(0,3)
                            let nextarr = res.data.slice(3,4)
                            commit('setPortfolioListpre',prearr)
                            commit('setPortfolioListnext',nextarr)
                        }else if(res.data.length == 5){
                            let prearr = res.data.slice(0,3)
                            let nextarr = res.data.slice(3,5)
                            commit('setPortfolioListpre',prearr)
                            commit('setPortfolioListnext',nextarr)
                        }else if(res.data.length == 6){
                            let prearr = res.data.slice(0,3)
                            let nextarr = res.data.slice(3,6)
                            commit('setPortfolioListpre',prearr)
                            commit('setPortfolioListnext',nextarr)
                        }
                    }
                    //commit('setPortfolioList',res.data)
                }
            }
        }catch (e) {
        }
    },
    async getBoosterReviews({ commit,dispatch },value){
        try {
            let obj = value
            const res = await getBoosterReviews(obj)
            if(res.code == 200){
                if(res.data.data.length > 0){
                    res.data.data.forEach(element => {
                        element.scorenum = Math.ceil(Number(element.score))
                    });
                    console.log(res.data.data)
                    commit('setReviewsList',res.data.data)
                    commit('setReviewsTotal',res.data.meta.pagination.total)
                    commit('setReviewsTotalPages',res.data.meta.pagination.total_pages)

                }else{
                    commit('setReviewsList',[])
                }
                
            }
        }catch (e) {
        }
    },
    async getBoosterOrders({ commit,dispatch },value){
        try {
            let obj = value
            const res = await getBoosterOrders(obj)
            if(res.code == 200){
                commit('setOrderList',res.data.data)
                commit('setOrderTotal',res.data.meta.pagination.total)
                commit('setOrderTotalPages',res.data.meta.pagination.total_pages)
            }
        }catch (e) {
        }
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}