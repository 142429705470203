import router from '@/router/index.js';
import {
    ElMessage
} from 'element-plus';
const state = {
    tab: 1,
    inQueue: false,
    orderDetail: null,
    orderId: Number,
    accept: true,
    banned: false,
    region: null,
    waitTime: 0,
    passTime:0,
    boosterProfile:{
        username:'',
        user_id:'',
        avatar:''
    }
}
const getters = {

}
const mutations = {
    setBoosterProfile(state, boosterProfile) {
        state.boosterProfile = boosterProfile;
    },
    setOrderDetail(state, orderDetail) {
        state.orderDetail = orderDetail;
    },
    setPassTime(state, passTime) {
        state.passTime = passTime;
    },
    setOrderId(state, orderId) {
        state.orderId = orderId;
    },
    setWaitTime(state, waitTime) {
        state.waitTime = waitTime;
    },
    setRegion(state, region) {
        state.region = region;
    },
    setBanned(state, banned) {
        state.banned = banned
    },
    setQueue(state, inQueue) {
        state.inQueue = inQueue
    },
    setAccept(state, accept) {
        state.accept = accept
    },
    setTab(state, tab) {
        state.tab = tab
    },
}
const actions = {
    setBoosterProfile({
        state,
        commit
    }, value) {
        commit('setBoosterProfile', value)
    },
    setOrderDetail({
        state,
        commit
    }, value) {
        commit('setOrderDetail', value)
    },
    setPassTime({
        state,
        commit
    }, value) {
        commit('setPassTime', value)
    },
    setOrderId({
        state,
        commit
    }, value) {
        commit('setOrderId', value)
    },
    setWaitTime({
        state,
        commit
    }, value) {
        commit('setWaitTime', value)
    },
    setRegion({
        state,
        commit
    }, value) {
        commit('setRegion', value)
    },
    setBanned({
        state,
        commit
    }, value) {
        commit('setBanned', value)
    },
    setQueue({
        state,
        commit
    }, value) {
        commit('setQueue', value)
    },
    setAccept({
        state,
        commit
    }, value) {
        commit('setAccept', value)
    },
    setTab({
        state,
        commit
    }, value) {
        commit('setTab', value)
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}