import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate"
import user from "./modules/user";
import calculate from "./modules/calculate";
import booster_order from "./modules/booster_order";
import booster_profile from "./modules/booster_profile";
import support from "./modules/support";
import user_booster from "./modules/user_booster";
import user_home from "./modules/user_home";
import user_blog from "./modules/user_blog";
const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  modules: {
    user,
    calculate,
    booster_order,
    booster_profile,
    support,
    user_booster,
    user_home,
    user_blog
  },
  strict: debug,
  plugins:[createPersistedState({
    storage:window.sessionStorage
  })]
});