import request from "@/utils/request"
export function getProfileInfo(data){
    return request({
        url: "/v1/web/booster/profile/info",
        method: "post",
        data
    });
}
export function getBoosterPortfolio(data){
    return request({
        url: "/v1/web/booster/profile/portfolio",
        method: "post",
        data
    });
}
export function getBoosterReviews(data){
    return request({
        url: "/v1/web/booster/profile/reviews",
        method: "post",
        data
    });
}
export function getBoosterOrders(data){
    return request({
        url: "/v1/web/booster/history/order",
        method: "post",
        data
    });
}