import router from '@/router/index.js';
import {
    removeAllCookie,
    getCookie,
    setCookie
} from "@/utils/cookie";
import {
    ElMessage
} from 'element-plus';
import { indexBlog } from "@/api/common.js"
const state = {
    blogList:[],
    page:1,
    limit:12,
    total:null,
    totalPage:null,
    info:null
}
const getters = {

}
const mutations = {
    setInfo(state, info) {
        state.info = info
    },
    setBlogList(state, blogList) {
        state.blogList = blogList
    },
    setTotal(state, total) {
        state.total = total
    },
    setTotalPage(state, totalPage) {
        state.totalPage = totalPage
    },
    setPage(state, page) {
        state.page = page
    },
}
const actions = {
    setInfo({ state, commit }, value) {
        commit('setInfo', value)
    },
    setBlogList({ state, commit }, value) {
        commit('setBlogList', value)
    },
    setTotal({ state, commit }, value) {
        commit('setTotal', value)
    },
    setTotalPage({ state, commit }, value) {
        commit('setTotalPage', value)
    },
    setPage({ state, commit }, value) {
        commit('setPage', value)
    },
    async getBlogList({ state,commit,dispatch },value){
        try {
            let obj = value
            const res = await indexBlog(obj)
            if(res.code == 200){
                let arr = state.blogList.concat(res.data.data)
               commit('setBlogList',arr)
               commit('setTotal',res.data.meta.pagination.total)
               commit('setTotalPage',res.data.meta.pagination.total_pages)
            }
        }catch (e) {
            console.log('errot========')
            console.error(e)
        }
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}