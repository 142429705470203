import request from "@/utils/request"
export function getPrivacy(data){
    return request({
        url: "/v1/web/privacy/policy",
        method: "post",
        data
    });
}
export function getTerms(data){
    return request({
        url: "/v1/web/terms/conditions",
        method: "post",
        data
    });
}
export function contactUs(data){
    return request({
        url: "/v1/web/contact/us",
        method: "post",
        data
    });
}
export function getFaq(data){
    return request({
        url: "/v1/web/index/faq",
        method: "post",
        data
    });
}
export function getStatistics(data){
    return request({
        url: "/v1/web/digital/statistics",
        method: "post",
        data
    });
}
export function getContacts(data){
    return request({
        url: "/v1/web/index/contact",
        method: "post",
        data
    });
}
